<template>
  <div class="home-view">
    <!-- <NavBar  :active="'home'" /> -->
    <div class="home-content">
        <div class="splash-img">
            <img src="../assets/RacketNation_LOGO/RacketNation_Logo-12.png" alt="">
        </div>
        <h3 style="text-align:center;">Coming Soon</h3>
    </div>
    <!-- <FooterComponent  :active="'home'" /> -->
  </div>
</template>

<script>
// import NavBar from '../components/NavBar.vue'; 
// import FooterComponent from '../components/FooterComponent.vue';

export default {
  components: {
    // NavBar,
    // FooterComponent 
  },
  // Other component options
}
</script>

<style scoped>
    .home-view {
        background:black;
        color:white;
        background-size:cover;
        background-repeat:no-repeat;
        min-height:100vh;
    }

    .home-content {
        padding:50px;
    }
    
    .splash-img {
        width:600px;
        margin:auto;
    }

    .splash-img img {
        width:100%;
    }

    @media screen and (max-width:700px) {
        .splash-img {
            width:80%;
        }
    }
</style>